<template>
  <v-card
    v-click-outside="closeModal"
    class="add-modal rounded-lg mb-2"
    elevation="7">
    <CyButton
      class="close-btn mt-2"
      theme="primary"
      variant="tertiary"
      icon="close"
      icon-only
      @click="closeModal"/>
    <v-card-title class="header__title">
      {{ $t('environment.new') }}
    </v-card-title>
    <v-card-subtitle class="header__subtitle">
      {{ $t('environment.description') }}
      <a
        :href="$docLinks.project.environments"
        target="_blank"
        rel="noopener noreferrer"
        class="cy-link"
        v-text="$t('learnMore')"/>
    </v-card-subtitle>

    <v-divider/>

    <v-card-text class="content">
      <div class="d-flex space-x-4">
        <div>
          <label>{{ $t('color') }}</label>
          <CyInputsColorPicker
            icon="mdi-layers-outline"
            :color-name.sync="formData.color"/>
        </div>
        <v-text-field
          v-model.trim="$v.formData.name.$model"
          class="required-field"
          :label="$t('forms.fieldName')"
          :error-messages="nameErrors"
          required
          @blur="$v.formData.name.$touch()"/>
      </div>
    </v-card-text>

    <v-divider/>

    <v-card-actions class="actions d-flex align-center space-x-4">
      <CyButton
        class="ml-auto"
        theme="primary"
        variant="secondary"
        @click="closeModal">
        {{ $t('forms.btnCancel') }}
      </CyButton>
      <CyButton
        v-has-rights-to="'UpdateProject'"
        :disabled="$v.$invalid"
        :loading="saving"
        theme="secondary"
        variant="primary"
        @click="createEnvironment">
        {{ $t('environment.add') }}
      </CyButton>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import CyInputsColorPicker from '@/components/CyInputsColorPicker.vue'
import REGEX from '@/utils/config/regex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyEnvironmentAddModal',
  components: {
    CyInputsColorPicker,
  },
  validations () {
    return {
      formData: {
        name: {
          required,
          isNameValid: (val) => REGEX.ENV_NAME.test(val),
        },
      },
    }
  },
  data: () => ({
    saving: false,
    formData: {
      color: '',
      name: '',
    },
  }),
  computed: {
    nameErrors () {
      const errors = []
      const { $dirty, required, isNameValid } = this.$v.formData.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!isNameValid && !_.isEmpty(this.formData.name)) errors.push(this.$t('environment.nameInvalid'))
      return errors
    },
    envCanonical () {
      return this.$getSlug(this.formData.name)
    },
  },
  methods: {
    ...mapActions('organization/project', [
      'GET_PROJECT',
      'CREATE_ENV',
    ]),
    async createEnvironment () {
      this.$toggle.saving(true)
      const { projectCanonical } = this.$route.params
      const { envCanonical, formData: { color, name } } = this

      const environment = {
        color,
        name,
        canonical: envCanonical,
      }

      const success = await this.CREATE_ENV({ environment })

      if (success) {
        await this.GET_PROJECT({ projectCanonical, envCanonical, fetchDeps: true })

        this.$router.push({
          name: 'environmentOverview',
          params: {
            envCanonical,
            projectCanonical,
          },
        })
      } else {
        this.$toggle.saving(false)
      }
    },
    closeModal () {
      this.$v.$reset()
      this.$resetData('formData')
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.add-modal {
  $offset: 8px;

  display: flex;
  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  flex-direction: column;
  width: 60%;
  max-width: 800px;
  height: calc(100% - #{$offset} * 2);
  overflow: hidden;

  .close-btn {
    position: absolute;
    top: $spacer-4;
    right: $spacer-4;
  }

  .header {
    &__title {
      margin-top: $spacer-4;
      margin-bottom: $spacer-4;
      padding-left: $spacer-5;
      font-size: $font-size-h3;
      font-weight: $font-weight-bolder;
    }

    &__subtitle {
      padding-left: $spacer-5;
      color: cy-get-color("primary");
      font-size: $font-size-lg;
      font-weight: $font-weight-default;
      line-height: $line-height-default;
    }
  }

  .content {
    height: 100%;
    padding-top: $spacer-6;
    padding-left: $spacer-5;
    background-color: cy-get-color("grey", "light-4"),
  }

  label {
    color: cy-get-color("grey", "dark-2");
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }

  ::v-deep .v-label {
    color: cy-get-color("grey", "dark-2");
  }

  .color-picker {
    margin-top: $spacer;
  }

  .actions {
    padding: $spacer-5;
  }
}
</style>
